const konfiguration = {
  ssoAnbieter: false,
  anbieternummer: '0204000731',
  werbeeinwilligungInRegistrierungAnzeigen: false,
  startseite: {
    kreisUmIcons: false,
    zeilenumbruchVorAnbietername: false
  },
  meineDaten: {
    beitraegeUndZulagen: {
      zulagenAnzeigen: false,
      tabellenDekoration: ' '
    }
  },
  buttons: {
    vorangestelltesIconZeigen: false,
    preIcon: 'fa-caret-right',
    nachgestelltesIconZeigen: false,
    postIcon: 'fa-caret-right'
  },
  zulagerechner: {
    meldung: 'Sie nutzen nicht die volle Zulage, die Ihnen zusteht. Bitte wenden Sie sich an Ihren persönlichen Berater vor Ort, um Ihren optimalen Beitrag errechnen zu lassen. Gern können Sie sich auch mit unserem Kunden-Service-Center unter 089 / 6236-53-10  in der Zeit von 08:00 bis 18:00 Uhr in Verbindung setzen.',
    zusaetzlicherButton: false,
    buttonZiel: '',
    buttonText: ''
  },
  titel: 'Versicherungskammer Bayern',
  footer: {
    impressum: 'https://www.vkb.de/impressum',
    datenschutz: 'https://www.vkb.de/datenschutz',
    kontakt: 'https://www.vkb.de/kontakt'
  }
}
export default konfiguration;

